import React from 'react';
import { Button, IconArrowLeft, useTheme } from '@veneer/core';
import {
  DependencyManagerProvider,
  useDependencyManagerContext
} from 'src/contexts/dependencyManager';
import { closeServiceInstance } from 'src/utils/jweb';

const BackButton: React.FC = () => {
  const { jweb } = useDependencyManagerContext();
  const theme = useTheme();

  return (
    <Button
      appearance="ghost"
      customStyle={{ color: theme.color.foreground.default }}
      data-testid="back-button"
      leadingIcon={<IconArrowLeft />}
      onClick={() => closeServiceInstance(jweb)}
      small
    />
  );
};

const BackButtonWithProviders: React.FC = () => (
  <DependencyManagerProvider>
    <BackButton />
  </DependencyManagerProvider>
);

export default BackButtonWithProviders;
